import { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/DashBoard.js'
import PeriodoFacturacion from '../pages/PeriodoFacturacion.js'

const AppRoutes = memo(() => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/facturas/periodo" element={<PeriodoFacturacion />} />
        </Routes>
    )
})


export default AppRoutes