import { Flex, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";

const PingResponseBar = () => {
    const [criticalError, setCriticalError] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://nassau-api.981block.com/ping");
                const data = await response.json();
                if (data.error.result) {
                    setError(data.error.message);
                }
            } catch (error) {
                console.error("🚀 ~ file: PingResponseBar.js:19 ~ fetchData ~ error:", error);
                setCriticalError(true);
            }
        };

        fetchData();
    }, []);

    return (
        <Flex bg={!criticalError && !error ? "rgba(0, 255, 0, 0.3)" : "rgba(255, 0, 0, 0.3)"} p={2} justifyContent="center">
            {criticalError && <Text color="white">ERROR CRÍTICO DEL SISTEMA</Text>}
            {error && <Text color="white">ERROR: {error}</Text>}
            {!criticalError && !error && <Text color="white">SISTEMA OPERATIVO</Text>}
        </Flex>
    );
};

export default PingResponseBar;
