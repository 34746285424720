import { Box, Text } from '@chakra-ui/react'
import { memo, useEffect, useState } from 'react'
import Facturas from '../components/Facturas/Facturas'
import { getPaymentsByRangeOfDates } from '../services/paymentCalls'
import { useLocation, Link } from 'react-router-dom'

const PeriodoFacturacion = memo(() => {

    const { startDate, endDate } = useLocation().state
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchReport = async () => {
            if (startDate && endDate) {
                try {
                    const response = await getPaymentsByRangeOfDates(
                        startDate,
                        endDate
                    )
                    setResponse(response)
                } catch (error) {
                    console.error(
                        '🚀 ~ file: PeriodoFacturacion.js ~ line 104 ~ fetchReport ~ error',
                        error
                    )
                    setError(error)
                }
            }
        }

        fetchReport()
    }, [startDate, endDate])

    return (
        <Box>
            <Box
                w="100%"
                maxW="md"
                mx="auto"
                my={8}
                p={8}
                borderWidth={1}
                borderRadius={8}
                boxShadow="lg">
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                    Facturación del{' '}
                    {startDate && endDate
                        ? `${startDate} al ${endDate}`
                        : 'periodo seleccionado'}
                </Text>
            </Box>
            
            {response && (
                <Facturas
                    items={response || []}
                />
            )}

            {error && <Text color="red.500">{error.message}</Text>}

            <Link to="/">
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                    Volver
                </Text>
            </Link>

        </Box>
    )
})

export default PeriodoFacturacion
