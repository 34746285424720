import axios from "axios"

const API_URL = "https://nassau-api.981block.com"

axios.defaults.headers.common['api-key'] = `E1UbDxj3m5PXc0dmS6erkR8cSGMQiBWYyBgXqh0quB`

export const getPayment = async (invoice_id) => {

    try {
        const { data } = await axios.get(`${API_URL}/invoice/${invoice_id}`)
        return data.response.body.data;
    } catch (error) {   
        console.error("🚀 ~ file: bit2me.controller.js:66 ~ error", error);
        return error
    }

}

export const getPaymentByDate = async (date) => {

    try {
        const { data } = await axios.get(`${API_URL}/invoice/date/${date}`)
        return data.response.body.data;
    } catch (error) {   
        console.error("🚀 ~ file: bit2me.controller.js:66 ~ error", error);
        return error
    }

}

export const getPaymentsByRangeOfDates = async (startDate, endDate) => {

    try {
        const { data } = await axios.get(`${API_URL}/invoice/date/range/${startDate}/${endDate}`)
        return data.response.body.data;
    } catch (error) {   
        console.log("🚀 ~ file: paymentCalls.js:33 ~ getPaymentsByRangeOfDates ~ error:", error)
        throw new Error(error.message)
    }

}

export const getAllPayments = async () => {

    try {
        const { data } = await axios.get(`${API_URL}/invoices`)
        return data.response.body.data;
    } catch (error) {   
        console.log("🚀 ~ file: paymentCalls.js:33 ~ getPaymentsByRangeOfDates ~ error:", error)
        throw new Error(error.message)
    }

}