import { memo, useEffect, useState } from 'react'
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import { getAllPayments, getPaymentsByRangeOfDates } from '../services/paymentCalls'
import Facturas from '../components/Facturas/Facturas'
import Loggin from '../components/Loggin/Loggin'
import InfoBox from '../components/InfoBox/InfoBox'
import PingResponseBar from '../components/PingResponseBar/PingResponseBar'

const Dashboard = memo(() => {

    // Logging
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    // Define un estados
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [error, setError] = useState(null)
    const [invoices, setInvoices] = useState([])
    const [week, setWeek] = useState([])
    const [month, setMonth] = useState([])
    const [rangeInvoice, setRangeInvoice] = useState([])
    const [isReportVisible, setIsReportVisible] = useState(false)

    // Define el manejador para el login
    const handleLogin = (isUserLoggedIn) => {
        setIsLoggedIn(isUserLoggedIn)
    }

    // Define el manejador para mostrar el informe de facturación
    const handleGenrateReport = async () => {
        setIsReportVisible(true)
    }

    // Define el manejador para ocultar el informe de facturación
    const handleHideReport = async () => {
        setIsReportVisible(false)
    }

    // Gestiona la llamada a la API para obtener las facturas en un rango de fechas
    useEffect(() => {
        const fetchReport = async () => {
            if (startDate && endDate) {
                try {
                    const rangeInvoice = await getPaymentsByRangeOfDates(
                        startDate,
                        endDate
                    )
                    setRangeInvoice(rangeInvoice)
                } catch (error) {
                    console.error('🚀 ~ file: PeriodoFacturacion.js ~ line 104 ~ fetchReport ~ error', error)
                    setError(error)
                }
            }
        }

        fetchReport()
    }, [startDate, endDate])

    // Obtiene el rango de fechas de la semana actual
    const getCurrentWeek = () => {
        const today = new Date()
        const firstday = new Date(today)
        firstday.setDate(today.getDate() - today.getDay())

        const lastday = new Date(today)
        lastday.setDate(today.getDate() - today.getDay() + 6)

        // Formatear las fechas en el formato "YYYY-MM-DD"
        const formattedFirstDay = firstday.toISOString().split('T')[0]
        const formattedLastDay = lastday.toISOString().split('T')[0]

        return { firstday: formattedFirstDay, lastday: formattedLastDay }
    }

    // Obtiene el rango de fechas del mes actual
    const getCurrentMonth = () => {
        const today = new Date()
        const firstday = new Date(today.getFullYear(), today.getMonth(), 1)
            .toISOString()
            .slice(0, 10)
        const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0)
            .toISOString()
            .slice(0, 10)

        return { firstday, lastday }
    }

    // Gestiona la llamada a la API para obtener las facturas
    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentWeek = getCurrentWeek()
                const currentMonth = getCurrentMonth()

                const invoices = await getAllPayments()
                const week = await getPaymentsByRangeOfDates(
                    currentWeek.firstday,
                    currentWeek.lastday
                )
                const month = await getPaymentsByRangeOfDates(
                    currentMonth.firstday,
                    currentMonth.lastday
                )

                setInvoices(invoices)
                setWeek(week)
                setMonth(month)
            } catch (error) {
                console.error('🚀 ~ file: DashBoard.js ~ line 104 ~ fetchInvoices ~ error', error)
                setError(error)
            }
        }

        fetchData()
    }, [])

    // calcular el numero total de facturas
    const calculateTotalInvoices = (invoices) => {
        return invoices.length
    }

    // calcular el total de facturas del mes
    const calculateTotalInvoicesMonth = (month) => {
        return month.length
    }

    // calcular el total de facturas de la semana
    const calculateTotalInvoicesWeek = (week) => {
        return week.length
    }

    const totalEur = invoices.reduce(
        (acc, invoices) => acc + invoices.amount_eur,
        0
    )
    const totalComision = invoices.reduce(
        (acc, invoices) => acc + invoices.comision,
        0
    )
    const totalInvoices = calculateTotalInvoices(invoices)
    const totalEurWeek = week.reduce((acc, week) => acc + week.amount_eur, 0)
    const totalComisionWeek = week.reduce((acc, week) => acc + week.comision, 0)
    const totalEurMonth = month.reduce((acc, month) => acc + month.amount_eur, 0)
    const totalComisionMonth = month.reduce((acc, month) => acc + month.comision,0)
    const totalInvoicesWeek = calculateTotalInvoicesWeek(week)
    const totalInvoicesMonth = calculateTotalInvoicesMonth(month)

    return (
        <Box mx={4}>

            {!isLoggedIn && (
                <Loggin onLogin={handleLogin} />
            )}

            {isLoggedIn && (
                <>
                    
                    <PingResponseBar />
                    
                    <Flex direction="row" justifyContent="space-between" gap={4}>
                        
                        <InfoBox
                            title="Facturación total"
                            totalEur={totalEur.toFixed(2)}
                            totalComision={totalComision.toFixed(2)}
                            totalInvoices={totalInvoices}
                        />

                        <InfoBox
                            title="Facturación semana actual"
                            totalEur={totalEurWeek.toFixed(2)}
                            totalComision={totalComisionWeek.toFixed(2)}
                            totalInvoices={totalInvoicesWeek}
                        />

                        <InfoBox
                            title="Facturación mes actual"
                            totalEur={totalEurMonth.toFixed(2)}
                            totalComision={totalComisionMonth.toFixed(2)}
                            totalInvoices={totalInvoicesMonth}
                        />

                    </Flex>

                    <Box
                        as="form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleGenrateReport()
                        }}
                        p={8}
                        borderWidth={1}
                        borderRadius={8}
                        boxShadow="md"
                        display="flex"
                        flexDirection="column"
                        width="100%"
                    >
                        <Flex direction="row" mb={4}>
                            <Box flex="1" mr={4} mb={0}>
                                <Text fontSize="lg" mb={2}>
                                    Seleccionar fecha incio de facturación:
                                </Text>
                                <Input
                                    type="date"
                                    value={startDate || ''}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    mb={4}
                                />
                            </Box>
                            <Box flex="1" mb={0}>
                                <Text fontSize="lg" mb={2}>
                                    Seleccionar fecha fin de facturación:
                                </Text>
                                <Input
                                    type="date"
                                    value={endDate || ''}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    mb={4}
                                />
                            </Box>
                        </Flex>

                        <Button mt={0} colorScheme="gray" type="submit" fontSize="lg">
                            Generar informe
                        </Button>
                    </Box>

                    {error && (
                        <Text color="red.500" mt={4}>
                            {error.message}
                        </Text>
                    )}

                    {isReportVisible && rangeInvoice && (
                        <Box
                            p={8}
                            my={8}
                            borderWidth={1}
                            borderRadius={8}
                            boxShadow="md"
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >                    
                            {rangeInvoice && (
                                <Facturas
                                    items={rangeInvoice || []}                                   
                                />
                            )}
                            
                            <Button
                                mt={4}
                                colorScheme="gray"
                                type="submit"
                                fontSize="lg"
                                onClick={handleHideReport}
                            >
                                Ocultar informe
                            </Button>

                        </Box>
                    )}
                </>
            )}
        </Box>
    )
})

export default Dashboard
