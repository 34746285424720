import React from 'react'
import { Box, Table, Td, Text, Tr } from '@chakra-ui/react'

const InfoBox = ({ title, totalEur, totalComision, totalInvoices }) => {
    return (
        <Box
            w="100%"
            maxW="md"
            my={8}
            p={8}
            borderWidth={1}
            borderRadius={8}
            boxShadow="md"
        >
            <Text fontSize="xl" fontWeight="bold" mb={4}>
                {title}
            </Text>
            <Table variant="simple">
                <Tr>
                    <Td fontWeight="bold">Total:</Td>
                    <Td>{totalEur} €</Td>
                </Tr>
                <Tr>
                    <Td fontWeight="bold">Comision:</Td>
                    <Td>{totalComision} €</Td>
                </Tr>
                <Tr>
                    <Td fontWeight="bold">Total facturas:</Td>
                    <Td>{totalInvoices}</Td>
                </Tr>
            </Table>
        </Box>
    )
}

export default InfoBox