import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { useState } from "react";

const Facturas = ({ items }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const totalEur = items.reduce((acc, item) => acc + item.amount_eur, 0);
    const totalComision = items.reduce((acc, item) => acc + item.comision, 0);

    const handleOpenModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const ItemGrid = ({ label, value }) => {
        return (
            value !== null && (
                <>
                    <GridItem>
                        <Text fontWeight="bold" whiteSpace="nowrap">
                            {label}:
                        </Text>
                    </GridItem>
                    <GridItem>
                        <Text fontWeight="bold" whiteSpace="nowrap">
                            {value}
                        </Text>
                    </GridItem>
                </>
            )
        );
    };

    return (
        <Box
            p={8}
            my={8}
            // borderWidth={1}
            // borderRadius={8}
            // boxShadow="mg"
            display="flex"
            flexDirection="column"
            width="100%">
            <Heading>FACTURAS</Heading>

            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Nº Ticket</Th>
                            <Th>Crypto</Th>
                            <Th>Euros</Th>
                            <Th>Comision</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {items.map((item) => (
                            <Tr
                                key={item.id}
                                onClick={() => handleOpenModal(item)}
                                _hover={{ cursor: "pointer", background: "rgba(169, 169, 169, 0.1)" }}>
                                <Td>{item.num_ticket}</Td>
                                <Td>
                                    {item.amount_cryto} {item.crypto}
                                </Td>
                                <Td>{item.amount_eur} €</Td>
                                <Td>
                                    {item.comision} ({item.porcentaje_comision})
                                </Td>
                            </Tr>
                        ))}
                        <Tr key="total">
                            <Th colSpan={2}>Total</Th>
                            <Td>{totalEur} €</Td>
                            <Td>{totalComision} €</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            {selectedItem && (
                <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="2xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize="xl" fontWeight="bold" textAlign="center">
                            {`Detalles de la factura - Nº Ticket: ${selectedItem.num_ticket}`}
                        </ModalHeader>
                        <ModalBody>
                            <Box>
                                <Grid templateColumns="repeat(2, 1fr)" gap={1}>
                                    <ItemGrid label="ID" value={selectedItem._id} />
                                    <ItemGrid label="Foreing id" value={selectedItem.foreignId} />
                                    <ItemGrid label="Invoice id" value={selectedItem.invoiceId} />
                                    <ItemGrid label="Crypto" value={selectedItem.crypto} />
                                    <ItemGrid label="Cantidad crypto" value={selectedItem.amount_cryto} />
                                    <ItemGrid label="Precio base" value={selectedItem.base_price} />
                                    <ItemGrid label="Cantidad euros" value={selectedItem.amount_eur} />
                                    <ItemGrid label="Pago recibido" value={selectedItem.paymentReceived ? "✅" : "❌"} />
                                    <ItemGrid label="Correo" value={selectedItem.email} />
                                    <ItemGrid label="Propina" value={selectedItem.tip} />
                                    <ItemGrid label="Estado" value={selectedItem.status} />
                                    <ItemGrid label="Porcentaje comision" value={selectedItem.porcentaje_comision} />
                                    <ItemGrid label="Comision" value={selectedItem.comision} />
                                    <ItemGrid label="Invoice id" value={selectedItem.original_data.invoiceId} />
                                    <ItemGrid label="Fecha creación" value={selectedItem.original_data.createdAt} />
                                    <ItemGrid label="Fecha de actualización" value={selectedItem.original_data.updatedAt} />
                                    <ItemGrid label="Fecha de caducidad" value={selectedItem.original_data.expiredAt} />
                                    <ItemGrid label="Fecha de pago" value={selectedItem.original_data.paidAt} />
                                    <ItemGrid label="Descripción" value={selectedItem.original_data.description} />
                                    <ItemGrid label="Cantidad pagada por debajo" value={selectedItem.original_data.underpaidAmount} />
                                    <ItemGrid label="Cantidad pagada en exceso" value={selectedItem.original_data.overpaidAmount} />
                                    <ItemGrid label="Dirección de pago" value={selectedItem.original_data.paymentAddress} />
                                    <ItemGrid label="Comisión B2M" value={selectedItem.original_data.feeAmount} />
                                </Grid>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button mt={4} colorScheme="gray" type="submit" fontSize="lg" onClick={handleCloseModal} mx="auto" w="100%">
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    );
};

export default Facturas;
